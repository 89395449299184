import * as React from "react"

const SSRPage = ({ serverData }) => (
  <main>
    <h1>SSR Page with Dogs</h1>
    {serverData.content.publish_date}
  </main>
)

export default SSRPage

export async function getServerData(props) {
  const slug = "54a0b099-2348-43a2-b080-352e7a3aabfd"

  try {
    const res = await fetch(`https://feed.mfn.se/v1/item/${slug}.json`)

    if (!res.ok) {
      throw new Error(`Response failed`)
    }

    return {
      props: await res.json(),
    }
  } catch (error) {
    return {
      status: 500,
      headers: {},
      props: {},
    }
  }
}
